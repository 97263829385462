<template>
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">
        دخول غير مصرح به 🔐
      </h2>
      <p class="mb-2">
        أنت غير مشترك بهذه الخدمة
      </p>

      <b-button
        variant="primary"
        class="mb-2 btn-sm-block"
        :to="isAuthenticated ? { name: 'admin:dashboard' } : { name: 'login'}"
      >
        العودة للصفحة الرئيسية
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BButton,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
